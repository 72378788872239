/*****************************************************************************
 * UmanIT - Menu
 *****************************************************************************/


const menuButton = document.querySelector("[data-trigger='display-menu']"),
  menuContainer = document.querySelector("[data-target='main-menu-container']"),
  header = document.querySelector("[data-layout='header']"),
  overlay = document.querySelector(".overlay");

let headerHeight;
let submenuActive =document.querySelector(".main-menu__item.active .sub-menu");
let submenuLink = Array.from(document.querySelectorAll("[data-trigger='display-submenu']")); // liens dans élément de menu principaux
let removeSubmenuButton = Array.from(document.querySelectorAll("[data-trigger='remove-submenu']")); // Uniquement mobile
let mainMenuItems = Array.from(document.querySelectorAll(".main-menu__item")); // éléments de menus principaux
let subMenuContainer = Array.from(document.querySelectorAll("[data-target='submenu-container']")); // div contenant l'ensemble du sous-menu
let initWindowWidth = window.innerWidth;
const canHover = window.matchMedia('(hover: hover)').matches; //true or false


window.addEventListener("load", function() {
  // Désactiver les liens sur les principaux items du menu
  submenuLink.forEach(function (btn) {
    if ((breakpoints.isSM()) || (breakpoints.isMD()) || (breakpoints.isLG()) || canHover == false) {
      btn.addEventListener("click", function (e) {
        e.preventDefault();
      });
    }
  });

});


// Uniquement en mobile (bouton uniquement affiché en mobile)
// Retourne au niveau 1 du menu lorsque l'on est au niveau 2
removeSubmenuButton.forEach(function (btn) {
  btn.addEventListener("click", function () {
    const parentLi = btn.closest("li");
    parentLi.classList.remove("active");
  });
});

window.addEventListener("resize", function () {
  if ((breakpoints.isSM()) || (breakpoints.isMD()) || (breakpoints.isLG()) || canHover == false ) {
    mainMenuItems.forEach(mainMenuItem => mainMenuItem.classList.remove("active"));
    if(initWindowWidth != window.innerWidth) {
      overlay.classList.remove("active");
    }
    subMenuContainer.forEach(submenu => submenu.removeAttribute("style"));

    submenuLink.forEach(function (btn) {
      if ((breakpoints.isSM()) || (breakpoints.isMD()) || (breakpoints.isLG()) || canHover == false) {
        btn.addEventListener("click", function (e) {
          e.preventDefault();
        });
      }
    });

  } else {
    menuContainer.removeAttribute("style");
  }
});

// Mobile
menuButton.addEventListener("click", function () {
  menuContainer.classList.toggle("active");
  header.classList.toggle("active");
  menuContainer.style.top = (header.offsetHeight - 1) + "px";
  overlay.classList.toggle("active");
  menuContainer.style.height = 'calc(100vh - ' + header.offsetHeight + 'px)';

  toggleScrollEvent();
});


// Mobile et desktop
// Items principaux du menu ("Foie gras de canard", "Viandes de canard", ...)
if (((breakpoints.isXL()) && canHover == true) || ((breakpoints.isXXL()) && canHover == true)) {
  submenuLink.forEach(function (btn) {
    btn.addEventListener("mouseenter", displaySubmenu);
    btn.addEventListener("focus", displaySubmenu);
  });
  // Cache tous les sous-menus
  hideDesktopSubmenu(mainMenuItems);
} else {
  submenuLink.forEach(function (btn) {
    btn.addEventListener("click", displaySubmenu);
  });
}

subMenuActiveFn();

// Resize
window.addEventListener("resize", function () {
  setTimeout(function () {
    if (((breakpoints.isXL()) && canHover == true) || ((breakpoints.isXXL()) && canHover == true)) {
      submenuLink.forEach(function (btn) {
        btn.removeEventListener("click", displaySubmenu);
        btn.addEventListener("mouseenter", displaySubmenu);
        btn.addEventListener("focus", displaySubmenu);
      });
      hideDesktopSubmenu(subMenuContainer);
    } else {
      submenuLink.forEach(function (btn) {
        btn.removeEventListener("mouseenter", displaySubmenu);
        btn.removeEventListener("focus", displaySubmenu);
        btn.addEventListener("click", displaySubmenu);
      });
    }
    toggleScrollEvent();
  }, 100);

  subMenuActiveFn();
});

// Orientation change
window.addEventListener("orientationchange", subMenuActiveFn);


// Scroll
window.addEventListener("scroll", function () {
  if (!(breakpoints.isSM()) || (breakpoints.isMD()) || (breakpoints.isLG()) || canHover == false) {
    menuContainer.classList.remove("active");
    mainMenuItems.map(mainMenuItem => mainMenuItem.classList.remove("active"));
    overlay.classList.remove("active");
    subMenuContainer.map(submenu => submenu.removeAttribute("style"));
  }
});

//// Fonctions

/**
 * Desktop : positionne le submenu en lui mettant un padding gauche, pour l'aligner avec .header
 *
 * return {void}
 */
function alignSubmenu() {
  if (((breakpoints.isXL()) && canHover == true) || ((breakpoints.isXXL()) && canHover == true)) {
    const header = document.querySelector(".header");
    const headerCoord = header.getBoundingClientRect();
    const submenuContainers = Array.from(document.querySelectorAll("[data-layout='submenu-container']"));

    submenuContainers.forEach(submenuContainer => {
      submenuContainer.style.marginLeft = `${headerCoord.x}px`;
    });
  } else {
    const submenuContainers = Array.from(document.querySelectorAll("[data-layout='submenu-container']"));
    submenuContainers.forEach(submenuContainer => {
      submenuContainer.style.marginLeft = 0;
    });
  }
}

/**
 * Fonction qui ajoute ou supprime la classe active sur les principaux items de la navigation principale
 *
 * @param parentLi : li qui contient l'item de menu principal et le sous menu associé
 * @param submenu :  sous-menu associé au li
 * return {void}
 */
function toggleActive(parentLi, submenu) {
  if (!parentLi.classList.contains("active")) {
    mainMenuItems.forEach(function (item) {
      item.classList.remove("active");
      overlay.classList.add("active");
      let ownSubmenu = item.querySelector("[data-target='submenu-container']");
      ownSubmenu.removeAttribute("style");

    });
    parentLi.classList.add("active");
    setTop(submenu);
  }
}

/**
 *  Fonction qui calcule la taille du header et applique cette valeur en position "top" pour le sous-menu
 *
 *  @param submenu : le container du submenu à afficher
 *  return {void}
 */
function setTop(submenu) {
  if (((breakpoints.isXL()) && canHover == true) || ((breakpoints.isXXL()) && canHover == true)) {
    headerHeight = header.offsetHeight;
    submenu.style.top = headerHeight-2 + "px";
  } else {
    submenu.removeAttribute("style");
  }
}

/**
 *  Fonction performance : réduit le nombre d'exécution au scroll
 *
 *  return {void}
 */
function debounce(func, wait = 20, immediate = true) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
 * Desktop : retire les classes "active" des éléments de menu principaux lorsque la souris quitte les sous-menu
 *
 * @param subMenuContainer : div contenant l'ensemble du sous-menu
 * return {void}
 */
function hideDesktopSubmenu(subMenuContainer) {
  subMenuContainer.forEach(function (submenu) {
    submenu.addEventListener("mouseleave", function () {
      mainMenuItems.forEach(function (li) {
        li.classList.remove("active");
        overlay.classList.remove("active");
      });
    });
  });
}

/**
 * Mobile & Desktop : Affichage du sous-menu
 *
 * @param btn : Item du menu principal
 * return {void}
 */
function displaySubmenu() {
  const parentLi = this.parentElement;
  const submenu = parentLi.querySelector("[data-target='submenu-container']");
  toggleActive(parentLi, submenu);
  alignSubmenu();
}

/**
 * Désactive le scroll
 *
 * return {void}
 */
  function noScroll() {
    window.scrollTo(0, 0);
}

/**
 * Détecte si un sous-menu est déployé
 *
 * return {void}
 */
  function subMenuActiveFn() {
  if (submenuActive) {
    setTimeout(function () {
      setTop(submenuActive);
    }, 100);
  }
}

/**
 * Désactive le scroll si mobile et si le menu est deployé
 *
 * return {void}
 */
function toggleScrollEvent() {
    if((breakpoints.isSM()) || (breakpoints.isMD()) || (breakpoints.isLG()) || canHover == false) {
      setTimeout(function () {
        if (menuContainer.classList.contains("active")) {
          window.addEventListener('scroll', noScroll);
        } else {
          window.removeEventListener('scroll', noScroll);
        }
      });
    } else {
      window.removeEventListener('scroll', noScroll);
    }
}
